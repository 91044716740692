/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

/* ==========================================================================
   Pathios theme.
   ========================================================================== */

$theme-primary: #1982ff;
$theme-secondary: #fff0e6;
$theme-black: #000000;
$theme-muted: #aaaaaa;

$theme-danger: #da4167;

.theme-layout {
  // @at-root body#{&} {
  //   color: $theme-dark-body-color;
  //   background-color: $theme-dark-body-bg;
  // }
  // body {
  //   color: $theme-dark-body-color;
  //   background-color: $theme-dark-body-bg;
  // }

  h6,
  .h6,
  h5,
  .h5,
  h4,
  .h4,
  h3,
  .h3,
  h2,
  .h2,
  h1,
  .h1,
  p {
    color: $theme-black;
  }

  .text-muted {
    color: $theme-muted !important;
  }
  .text-primary {
    color: $theme-primary !important;
  }
  .text-danger {
    color: $theme-danger !important;
  }

  .bg-secondary {
    background-color: $theme-secondary !important;
  }

  .badge.bg-secondary {
    color: $theme-primary !important;
  }

  .main-menu .navbar-header {
    height: 6rem;
  }

  .auth-wrapper.auth-cover .brand-logo img,
  .main-menu .navbar-header .navbar-brand .brand-logo img {
    max-width: 150px;
  }
  .nav .modern-nav-toggle {
    display: none;
  }

  .vertical-layout {
    .main-menu {
      .navigation {
        a.active {
          background: linear-gradient(
            118deg,
            $theme-primary,
            lighten($theme-primary, 10%)
          );
          box-shadow: 0 0 10px 1px lighten($theme-primary, 30%);
        }
      }
    }
  }

  a {
    color: $theme-primary;
  }

  .btn-primary {
    border-color: $theme-primary !important;
    background-color: $theme-primary !important;

    &:hover:not(.disabled):not(:disabled) {
      box-shadow: 0 8px 25px -8px $theme-primary;
    }
  }

  .alert-primary {
    color: $theme-primary !important;
  }

  .form-control:focus {
    border-color: $theme-primary;
    color: $theme-black;
    // box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
  }
  .input-group:not(.bootstrap-touchspin):focus-within .form-control,
  .input-group:not(.bootstrap-touchspin):focus-within .input-group-text {
    border-color: $theme-primary;
  }

  .select__multi-value,
  .react-select__multi-value {
    background-color: $theme-primary;
  }

  .avatar {
    &.bg-light-primary {
      color: $theme-primary !important;
      background: $theme-secondary !important;
    }
  }

  .page-item {
    .page-link {
      background-color: $theme-secondary;
      border: 0 solid $theme-secondary;
    }

    &.active {
      .page-link {
        background-color: $theme-primary;
        border-color: $theme-primary;
      }
    }

    &.disabled .page-link {
      background-color: #f9f9f9;
      border-color: $theme-secondary;
    }

    &.next-item .page-link:hover {
      background-color: $theme-primary !important;
    }

    &.prev-item .page-link:hover {
      background-color: $theme-primary !important;
    }
  }

  .flatpickr-calendar {
    .flatpickr-day {
      &.today {
        border-color: $theme-primary;
      }

      &.startRange,
      &.endRange,
      &.startRange:hover,
      &.endRange:hover {
        background: $theme-primary;
        border-color: $theme-primary;
      }

      &.selected.startRange + .endRange:not(:nth-child(7n + 1)),
      &.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
      &.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
        box-shadow: -10px 0 0 $theme-primary;
      }

      &.inRange,
      &.inRange:hover {
        background: $theme-secondary;
        border-color: $theme-secondary;
        box-shadow: -5px 0 0 $theme-secondary, 5px 0 0 $theme-secondary;
      }
    }
  }
}

/* ==========================================================================
   Base styles (global)
   ========================================================================== */

/* Loader */
.fallback-logo {
  max-width: 300px;
}

/* Forms */
.form-control {
  padding: 1rem 1rem;
  color: $theme-black;
}

/* Reactstrap Offcanvas  */
.offcanvas-end {
  width: 500px !important;
  .offcanvas-header {
    padding: 1.4rem 1.4rem;
    background: #f3f2f7;
  }
  .offcanvas-body {
    margin-top: 0px !important;
    padding: 0px !important;

    .sidebarFormFields {
      padding: 1.2rem;
      overflow: scroll !important;
    }

    .sidebarActionButtons {
      padding: 1.2rem;
      bottom: 0px;
      left: 0px;
      background: #f3f2f7;
      width: 100%;
    }
  }
}

/* React Select */

.select__value-container {
  padding: 10px 8px;
}

.select__control.select__control--is-focused {
  border-color: $theme-black !important;
}
.select__value-container.select__value-container--has-value {
  .select__single-value {
    color: $theme-black;
  }
}

.select {
  &__control .select__placeholder {
    color: #cccccc !important;
  }

  &__menu {
    margin: 0.125rem auto !important;
    color: $theme-black !important;

    .select__menu-list {
      z-index: 99999 !important;
      overflow-y: scroll !important;
      display: block !important;
    }
  }

  &__option {
    background-color: white !important;
    color: $theme-black !important;

    &--is-focused {
      background-color: $theme-secondary !important;
      color: $theme-black !important;
    }
  }

  // &__group {
  //   padding: 0;
  // }

  // &__menu-portal {
  //   border: 1px solid darkblue;
  // }
}

/* Buttons */
.btn {
  padding: 1.2rem 1.5rem;
}

/* Tables */
.react-dataTable
  .rdt_TableHead
  .rdt_TableHeadRow
  .rdt_TableCol
  .rdt_TableCol_Sortable {
  text-transform: none;
}
.react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  height: auto !important;
}

/* Sidebar */
.modal {
  .scrollbar-container {
    height: calc(100vh - 180px);
    padding-bottom: 3rem;
  }
}

.modal-slide-in .modal-dialog.sidebar-lg {
  width: 38rem;
  .sidebarActionButtons {
    position: fixed;
    bottom: 0;
    padding: 1.5rem;
    background: #f8f8f8;
    border-top: 1px #eee solid;
    left: 0;
    width: 100%;
  }
}

/* Transaction Card */
.transaction-item {
  margin-bottom: 0.3rem !important;
  margin-top: 0.3rem !important;

  &.card-item {
    margin-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
    border-top: 1px #eeeeee solid;
  }

  .transaction-title {
    margin-bottom: 0.2rem !important;
  }
}

/* Search Bar */
.search-trigger {
  border-right: 1px #eeeeee solid;
  padding: 8px 12px;
  margin-right: 8px;
}
.nav-search {
  .searchBar {
    width: 400px;
    // border: 5px red solid;

    input,
    button {
      background-color: #f9f9f9;
      border: none;
    }

    &.invalid {
      border: 1px $theme-danger solid;
    }
  }

  @media only screen and (max-width: 699px) {
    // position: fixed;
    // width: 100%;
    // height: 100%;
    // left: 0;
    // top: 60px;
    .searchBar {
      position: fixed;
      top: 60px;
      width: 100%;
      left: 0;
      box-shadow: 0 5px 25px rgb(34 41 47 / 30%);
      border: 1px $theme-muted solid;
      height: 70px;
      // background-color: pink;
    }
  }
}
